import { BillingPeriod, PaymentMethod, Customer, Card, Coupon, SubscriptionTool } from '@models';

export enum SubscriptionStatusEnum {
    AwaitingPayment = 0,
    Active = 1,
    Canceled = 2,
    CanceledByDownGrade = 3,
    CanceledByUpGrade = 4,
    CanceledByNonpayment = 5,
    ManualCancellationByCustomer = 6,
    ErrorProcessingRecurrence = 7,
    CanceledFoUpdate = 8,
    BillingByThirdParty = 9,
    CancellationRequested = 10
}

export enum SubscriptionTypeEnum {
    None = 0,
    RecurringSubscription = 1,
    ThemePurchase = 2,
    ThirdPartyPurchase = 3,
    Loose = 5
}

/* eslint-disable camelcase */
export enum SubscriptionVersion {
    v2_0 = 0,
    v3_0 = 1,
    v3_5 = 2
}
/* eslint-enable camelcase */

export type Subscription = {
    id?: number;
    createdDate: Date;
    type: SubscriptionTypeEnum;
    status: SubscriptionStatusEnum;
    billingPeriod: BillingPeriod;
    paymentMethod?: PaymentMethod;
    reasonForCancellation: string;
    cancellationDate?: Date;
    nextInvoiceDate: Date;
    lastCheckDate?: Date;
    customer: Customer;
    card?: Card;
    coupons: Array<Coupon>;
    subscriptionTools: Array<SubscriptionTool>;
    gatewayId: string;
    version: SubscriptionVersion;
};
