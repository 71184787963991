import { Product as ProductModel } from '@models';
import {
    currencyFormatter,
    getRandomArbitrary,
    isBrowser,
    isGreaterThanZero,
    LOCAL_STORAGE_TEMP_PRODUCTS_KEY
} from '@utils';
import { translator, TranslatorProps } from '~/hooks/useTranslator';
import { PreviewValue } from './generatePreview';

const COMPUTER_PRICE = 3_200;
const OLD_COMPUTER_PRICE = 4_200;
const COMPUTER_QUANTITY = 5;

const OLD_JEANS_PRICE = 149.9;
const JEANS_PRICE = 129.9;
const JEANS_QUANTITY = 2;

const HANDBAG_PRICE = 369.9;
const HANDBAG_QUANTITY = 3;
const PRODUCT_URL = 'https://enviou.com.br/';

type TranslatedType = PreviewValue | string | number;
export type Product = {
    name: TranslatedType;
    category: TranslatedType;
    quantity: number;
    image: string;
    url: string;
    price: number;
    salePrice: number;
    total: number;
    oldPrice?: number;
    formactedPrice?: string;
    formactedOldPrice?: string;
    formactedTotalPrice?: string;
    formactedSalePrice?: string;
    currency: PreviewValue;
    hasReview: boolean;
    additionalInformation?: string;
};

export const getPreviewProducts = (translatorData: TranslatorProps, category?: TranslatedType) => {
    const { products, categories } = translator(translatorData).subTools.repurchase.customizationVariables.preview;
    const { locale } = translatorData;
    const currency = products.currency;

    let tempProducts: ProductModel[] = [];
    try {
        tempProducts = isBrowser()
            ? (JSON.parse(localStorage.getItem(LOCAL_STORAGE_TEMP_PRODUCTS_KEY) || '[]') as unknown as ProductModel[])
            : [];
    } catch (error) {
        tempProducts = [];
    }

    let productList: Array<Product> = [];
    if (isGreaterThanZero(tempProducts.length)) {
        productList = tempProducts.map((item) => {
            const quantity = item.availableStock || 1;
            const price = item.price || item.salePrice;
            return {
                name: item.name,
                category: item.category,
                quantity,
                image: item.image,
                url: item.url,
                price,
                salePrice: item.salePrice,
                oldPrice: price,
                total: price * quantity,
                formactedPrice: currencyFormatter(price, locale, String(currency)),
                formactedOldPrice: currencyFormatter(price, locale, String(currency)),
                formactedTotalPrice: currencyFormatter(price * quantity, locale, String(currency)),
                formactedSalePrice: currencyFormatter(item.salePrice, locale, String(currency)),
                currency,
                hasReview: true,
                additionalInformation: item.additionalInformation
            } as Product;
        });
    } else {
        productList = [
            {
                name: products.computer,
                category: categories.electronics,
                quantity: COMPUTER_QUANTITY,
                image: 'https://host-imagem-web.com.br/data/enviou/modelos/exemplo-pc.jpg',
                url: PRODUCT_URL,
                price: COMPUTER_PRICE,
                salePrice: COMPUTER_PRICE,
                oldPrice: OLD_COMPUTER_PRICE,
                total: COMPUTER_PRICE * COMPUTER_QUANTITY,
                formactedPrice: currencyFormatter(COMPUTER_PRICE, locale, String(products.currency)),
                formactedOldPrice: currencyFormatter(OLD_COMPUTER_PRICE, locale, String(products.currency)),
                formactedTotalPrice: currencyFormatter(
                    COMPUTER_PRICE * COMPUTER_QUANTITY,
                    locale,
                    String(products.currency)
                ),
                formactedSalePrice: currencyFormatter(COMPUTER_PRICE, locale, String(currency)),
                currency,
                hasReview: false,
                additionalInformation: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iure, ut.'
            },
            {
                name: products.jeans,
                category: categories.pants,
                quantity: JEANS_QUANTITY,
                image: 'https://host-imagem-web.com.br/data/enviou/modelos/exemplo-calca.jpg',
                url: PRODUCT_URL,
                price: JEANS_PRICE,
                salePrice: JEANS_PRICE,
                total: JEANS_PRICE * JEANS_QUANTITY,
                oldPrice: OLD_JEANS_PRICE,
                formactedOldPrice: currencyFormatter(OLD_JEANS_PRICE, locale, String(products.currency)),
                formactedPrice: currencyFormatter(JEANS_PRICE, locale, String(products.currency)),
                formactedTotalPrice: currencyFormatter(JEANS_PRICE * JEANS_QUANTITY, locale, String(products.currency)),
                formactedSalePrice: currencyFormatter(JEANS_PRICE, locale, String(currency)),
                currency,
                hasReview: true
            },
            {
                name: products.handbag,
                category: categories.handbag,
                quantity: HANDBAG_QUANTITY,
                image: 'https://host-imagem-web.com.br/data/enviou/modelos/exemplo-bolsa.jpg',
                url: PRODUCT_URL,
                price: HANDBAG_PRICE,
                salePrice: HANDBAG_PRICE,
                total: HANDBAG_QUANTITY * HANDBAG_PRICE,
                currency,
                formactedPrice: currencyFormatter(HANDBAG_PRICE, locale, String(products.currency)),
                formactedTotalPrice: currencyFormatter(
                    HANDBAG_QUANTITY * HANDBAG_PRICE,
                    locale,
                    String(products.currency)
                ),
                formactedSalePrice: currencyFormatter(HANDBAG_PRICE, locale, String(currency)),
                hasReview: true
            }
        ];
    }

    if (category) {
        productList = productList.filter((product) => product.category === category);
    }

    return productList;
};

export const getRandomPreviewProducts = (
    translatorData: TranslatorProps,
    productsAmount: number,
    category?: TranslatedType
) => {
    const products = getPreviewProducts(translatorData, category);
    const newProducts: Array<Product> = [];

    const limiteProductIndex = 0.99;
    const minProductPrice = 109.9;
    const maxProductPrice = 10_000.0;

    for (let index = productsAmount; index > 0; index--) {
        const productIndex = Math.floor(getRandomArbitrary(0, products.length - limiteProductIndex));
        const product = { ...products[productIndex] };

        product.name = `${product.name} ${index}`;
        product.price = getRandomArbitrary(minProductPrice, maxProductPrice);
        product.quantity = getRandomArbitrary(1, productIndex);
        product.formactedPrice = currencyFormatter(product.price, translatorData.locale, String(product.currency));
        product.formactedOldPrice = currencyFormatter(
            product.oldPrice,
            translatorData.locale,
            String(product.currency)
        );

        newProducts.push(product);
    }

    return newProducts;
};
